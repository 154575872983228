import { AboutPageWrapper, AboutPageTable, AboutPageTitle, AboutPageInformation, AboutPagePicture, AboutPageContent, AboutPageText, ResumeButton, SkillsSection, 
         SkillsContainer, ImportantWord } from "./about-page.styles";
import SkillCard from "../skill-card/skill-card.component";

import PFP from '../../assets/personal-picture.jpg'
import Resume from '../../assets/Resume2024.pdf'

const AboutPage = () => {

    const SKILLS = [
        {
            name: 'Front-End',
            tools: ['HTML/CSS','React.js', 'Redux', 'SCSS', 'Styled Components', 'Material UI'],
            icon: 'https://i.imgur.com/8ldLMlC.png'
        },
        {
            name: 'Back-End',
            tools: ['Python', 'MongoDB', 'Firebase','SQLITE', 'MySQL', 'PHP'],
            icon:'https://i.imgur.com/odOmIFN.png'
        },
        {
            name: 'Tools',
            tools: ['WordPress', 'GITHUB', 'BASH', 'LINUX', 'JAVA', 'Monday'],
            icon: 'https://i.imgur.com/fxS4cCu.png'

        },
        {
            name: 'Design',
            tools: ['PHOTOSHOP', 'GIMP', 'Elementor', 'WORD', 'EXCEL', 'POWERPOINT'],
            icon: 'https://i.imgur.com/pTWXgRU.png'
        },
    ]

    return(
        <AboutPageWrapper>
            <AboutPageTable>
                <tbody>
                    <tr>
                        <td>
                            <AboutPagePicture src={PFP} height={650}/>
                        </td>
                        <td>
                            <AboutPageContent>
                                <AboutPageTitle>Hello, It's Ashad!</AboutPageTitle>
                                <AboutPageText>
                                    <AboutPageInformation>
                                    I am eager to learn about things in software development and <ImportantWord>explore challenging problems.</ImportantWord> Currently, I am pursuing my <ImportantWord>Bachelor of Computer Science with Co-op</ImportantWord> at the University of Windsor, while also working on <ImportantWord>various projects</ImportantWord> to <ImportantWord>hone my skills</ImportantWord>.<br /><br />Majority of my software development experience comes from working with <ImportantWord>full-stack</ImportantWord> projects, however, I also have experience in <ImportantWord>Java</ImportantWord>, <ImportantWord>Python</ImportantWord>, and <ImportantWord>C</ImportantWord>.
                                    </AboutPageInformation>
                                </AboutPageText>
                                <ResumeButton href={Resume} download>Download My Resumé</ResumeButton>
                            </AboutPageContent>
                        </td>
                    </tr>
                </tbody>
            </AboutPageTable>
            <SkillsSection>
                <AboutPageTitle>Skills</AboutPageTitle>
                <SkillsContainer>
                    {
                        SKILLS.map((skill, i) => (
                            <SkillCard key={i} skill={skill} />
                        ))
                    }
                </SkillsContainer>
            </SkillsSection>
        </AboutPageWrapper>
    )
}

export default AboutPage;