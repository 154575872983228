import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import CrwnClothing from '../assets/projects/CRWN.png'
import PW from '../assets/projects/PersonalWebsite.png'
import Matter from '../assets/projects/Matter.png'
import { AboutPageTitle } from '../components/about-page/about-page.styles'

const PageWrapper = styled.div`
    margin: 70px 15vw;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    @media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
        margin: 0 5vw;
    }
    gap: 90px;

    @media (max-width: 1250px) {
        gap: 30px;
    }  

    & .title {
        margin-bottom: -90px;
        @media (max-width: 1250px) {
            margin-bottom: -30px;
        }  
    }
`

const SectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: 1250px) {
        flex-direction: column;
    }

    img{
        width: 50%;

        @media (max-width: 1250px) {
            width: 100%;
        }   
    }
`

const SectionInfoWrapper = styled.div`
    @media (max-width: 1250px) {
        order: ${props => props.imageLeft ? "" : "1"};
    }

    h1{
        color: white;
        font-size:25px;
        padding: 0;
        margin: 0;
        font-weight: 500;
        @media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
            font-size: 20px;
        }
        color: rgb(255, 50, 10);
    }

    p{
        font-size: 20px;
        @media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
            font-size: 16px;
        }
        overflow-y: auto;
        padding-right: 5px;
    }

    span{
        background-color: gray;
        padding: 1px 6px;
        border-radius: 5px;
    }
`

export const ButtonWrapper = styled(Link)`
    color: white;
    font-style: italic;
    font-size: 18px;
    text-decoration: none;
    padding: 0;

    &:hover{
        color: rgb(255,50,10);
        transition: all 0.2s ease-in-out;
    }
`

export const TechsWrapper = styled.div`
    display: flex;
    column-gap: 15px;
    row-gap: 10px;
    flex-wrap: wrap;
`

const SectionInfo = ({ name, desc, link, techs }) => {
    return(
        <SectionInfoWrapper>
            <h1>{name}</h1>
            <TechsWrapper>
                {
                    techs.map((tech, r) => <span key={r}>{tech}</span>)
                }
            </TechsWrapper>
            <p>{desc}</p>
            {/**
             * 
             * TODO: Add View More links for each featured project, leading to a demo of it.
             * 
             */}
            {/* <ButtonWrapper to={link}>View More</ButtonWrapper> */}
        </SectionInfoWrapper>
    )
}

const ProjectSection = ({ image, imageLeft = false, name, desc, link, techs }) => {
    return(
        <SectionWrapper>
            {
                imageLeft ?
                <>
                    <img src={image} alt='project' />
                    <SectionInfo name={name} desc={desc} link={link} techs={techs} />
                </>
                :
                <>
                    <SectionInfo name={name} desc={desc} link={link} techs={techs} />
                    <img src={image} alt='project' />
                </>
            }
        </SectionWrapper>
    )
}

const MainProjects = () => {
    const SectionsInfo = [
        {
            image: CrwnClothing,
            name: "CRWN Clothing",
            imageLeft: true,
            desc: "A full-stack eccomerece website bulit using React and Firebase. Allows users to sign-in via Google with the help of Firebase Authentication. They can shop for products and add to them to cart, all thanks to a seamless user interface.",
            techs: ['React', 'Redux', 'GraphQL', 'FireBase', 'SCSS'],
            link: '/projects/crwn-clothing'
        },
        {
            image: Matter,
            name: "Matter",
            desc: "A full-stack social media website made with React and Django. Users can create accounts and sign-in to post whatever's on their mind, and view other users.",
            techs: ['React.js', 'Django', 'SQLite3'],
            link: '/projects/banking-app'
        },
        {
            image: PW,
            name: "Personal Website",
            imageLeft: true,
            desc: "This project is, well, what you're currently seeing. It's this website that serves as my personal website, made with React and Styled Components.",
            techs: ['React', 'Styled-Components', 'SCSS'],
            link: '/projects/personal-site'
        },
    ]

    return (
        <PageWrapper>
        <AboutPageTitle className='title'>Featured Projects</AboutPageTitle>
        <ButtonWrapper to='/all-projects'>View All Projects<i className="iconoir-arrow-right" style={{ marginBottom: "-3px", marginLeft: "8px"}}></i></ButtonWrapper>
        {
            SectionsInfo.map((section, i) => <ProjectSection key={i} image={section.image} name={section.name} imageLeft={section.imageLeft} desc={section.desc} link={section.link} techs={section.techs} />)
        }
        </PageWrapper>
    )
}

export default MainProjects