import styled from 'styled-components'

export const SkillWrapper = styled.div`
    border: 2px solid white;
    border-radius: 10px;
    height: 100%;
    position: relative;
    padding: 20px;
    flex: 0 1 25%;
`

export const SkillTitle = styled.h1`
    text-align: center;
    color: rgb(255, 50, 10);
    font-size: 2vw;
    margin-top: 60px;
    font-weight: bold;
    @media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
        font-size: 10vw;
    }
`

export const BulletWrapper = styled.div`

    text-transform: uppercase;
    font-size: 0.8vw;
    @media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
        font-size: 4.6vw;
    }
    background-color: gray;
    width: fit-content;
    /* margin: 0px 0px 0px 10%; */
    padding: 1px 6px;
    border-radius: 5px;

    & h3 {
        margin: 0px;
    }
`

export const SkillImageContainer = styled.div`
    text-align: center;
    display: flex;
    position: absolute; 
    top: -25px;
    left: calc(50% - 35px); 
    border: 2px solid white;
    padding: 10px;
    border-radius: 10px;
    background-color: gray;
`
export const SkillImage = styled.img`
    height: 80%;
    margin: auto;
`