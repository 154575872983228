import SkillBullet from "./skill-bullet.component";

import { SkillWrapper, SkillTitle, SkillImageContainer, SkillImage } from "./skill-card.styles";

const SkillCard = ({ skill }) => {
    const { name, tools, icon } = skill;

    return(
        <SkillWrapper>
            <SkillImageContainer>
                <SkillImage src={icon}/>
            </SkillImageContainer>
            <SkillTitle>{ name }</SkillTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px"}}>
                {
                    tools.map((tool) => (
                        <SkillBullet name={tool} />
                    ))
                }
            </div>
        </SkillWrapper>
    )
}

export default SkillCard;