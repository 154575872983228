import PW from './assets/projects/PersonalWebsite.png'
import CRWN from './assets/projects/CRWN.png'
import SM from './assets/projects/Matter.png'
import BH from './assets/projects/Unity.png'
import CLC from './assets/projects/CLC.png'
import Rolodex from './assets/projects/Rolodex.png'
import PT from './assets/projects/paw-tracer.png'
import LQ from './assets/projects/leos-quest.png'

export const PROJECT_DATA = [
    {
        name: 'Personal Website',
        filters: ['Front-End'],
        description: <div>You are currently on my personal website, a front-end React app that showcases my skills and projects. The website is designed to highlight my expertise and experience in web development, providing a platform to display my portfolio. The website utilizes dynamic routes with React Router, making it easy for visitors to navigate between different pages and access relevant information. In addition, the website has organized styles using styled-components, creating a visually appealing and consistent user experience.</div>,
        technologies: ['React.js', 'Styled-Components', 'GitHub'],
        github: '',
        liveLink: null,
        imageSrc: PW,
        priority: 4
    },
    {
        name: 'CRWN Clothing',
        filters: ['Front-End', 'Back-End'],
        description: <div>A full-stack eccomerece website bulit using React and Firebase. Allows users to sign-in via Google with the help of Firebase Authentication. They can shop for products and add to them to cart, all thanks to a seamless user interface.</div>,
        technologies: ['React.js', 'TypeScript', 'Firebase', 'Redux', 'GitHub'],
        github: '',
        liveLink: '',
        imageSrc: CRWN,
        priority: 5
    },
    {
        name: 'Social-Media Website',
        filters: ['Front-End', 'Back-End'],
        description: <div>This social media site is built using React on the front-end and Django on the back-end. Users can create an account and login using user authentication. The website allows users to post images and interact with other users, fostering a community-driven experience.<br /><br />The website's backend is built using Django, which uses SQLite3 for database management. SQLite3 is an efficient and lightweight database engine that is ideal for small to medium-sized web applications. Overall, this social media site provides a user-friendly and efficient platform for users to connect with one another, showcase their interests and engage in online conversations.</div>,
        technologies: ['Django', 'React.js', 'Custom APIs', 'SQLite3', 'GitHub'],
        github: '',
        liveLink: null,
        imageSrc: SM,
        priority: 4
    },
    {
        name: 'Paw Tracer',
        filters: ['Front-End', 'Back-End'],
        description: <div>Another full-stack website built with React and Firebase, allowing users to sign in via their Google accounts and post about their missing pets.</div>,
        technologies: ['React.js', 'Firebase', 'SCSS'],
        github: '',
        liveLink: null,
        imageSrc: PT,
        priority: 3
    },
    {
        name: 'Leo\'s Quest',
        filters: ['Others'],
        description: <div>This multi-level adventure game with various maps consists of challenging boss fights, which the player has to conquer at the end of each level to advance to the next Players can purhcase upgrades from coins they gain from defeating normal mobs and bosses. This game also features cut-scenes to add story to the experience as well as hints to from NPCs to advance in the levels.</div>,
        technologies: ['Unity', 'C#', 'Gimp'],
        github: '',
        liveLink: null,
        imageSrc: LQ,
        priority: 4
    },
    {
        name: 'Bullet Hell',
        filters: ['Others'],
        description: <div>This Unity-built bullet hell game features advanced physics, vectors, and multiple external packages, offering an immersive and challenging gaming experience. With intense battles and engaging gameplay mechanics, this game is sure to captivate players of all skill levels and provide an exciting, action-packed adventure.</div>,
        technologies: ['Unity', 'C#', 'Tiled', 'Photoshop'],
        github: '',
        liveLink: null,
        imageSrc: BH,
        priority: 3
    },
    {
        name: 'Foolproof Calculator',
        filters: ['Others'],
        description: <div>This command-line calculator, coded in C, boasts both unary and binary operations, along with recallable memory and support for variables. With a foolproof design and intuitive user interface, it provides a simple yet powerful tool for quick calculations and computations on the go.</div>,
        technologies: ['C'],
        github: '',
        liveLink: null,
        imageSrc: CLC,
        priority: 2
    },
    {
        name: 'Monsters Rolodex',
        filters: ['Front-End'],
        description: <div>The Rolodex is a web application built with React, using the useEffect and useState hooks. This was my first project with React, and it allows users to search for items and filter them based on various categories.</div>,
        technologies: ['React.js'],
        github: '',
        liveLink: null,
        imageSrc: Rolodex,
        priority: 2
    },
]